import Ready from '@/utils/ready';
import 'clampify';

const selector = '[data-clampify]';

(() => {
  Ready.watch(selector, (element) => {
    const params = element.dataset.clampify
      ? {
          ...JSON.parse(element.dataset.clampify),
        }
      : {};
    $clampify(element, {
      endsWith: '...',
      autoUpdate: 'true',
      ...params,
    });
  });
})();
