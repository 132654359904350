import Ready from '@/utils/ready';
// @ts-ignore
import Swiper from 'swiper';
import {Pagination, Autoplay, Navigation} from 'swiper';

Swiper.use([Pagination, Autoplay, Navigation]);

const selector = '[data-ref="keypointsNumbers"]';

class swiperFiveYears {
  el: any;
  instance: any = null;

  constructor(el: any, options = {}) {
    this.el = el.querySelector('[data-ref="keypointsNumbers"] .swiper-container');
    this.instantiate(this.el, options);
    this.addEventListeners();
  }

  private instantiate(el: any, options: any) {
    this.instance = new Swiper(el, {
      ...options,
    });
  }

  private addEventListeners() {
    this.instance.on('slideChange', () => {
      this.el.querySelector(`[key="${this.instance.activeIndex}"]`).click();
    })
  }
}

(() => {
  Ready.watch(selector, (element: any) => {
    let options = {
      slidesPerView: 1,
      navigation: {
        nextEl: '.fiveYears__keyPoints__swiper__nav__right',
        prevEl: '.fiveYears__keyPoints__swiper__nav__left',
      },
    };
    if (element.dataset.options) {
      options = JSON.parse(element.dataset.options);
    }
    return new swiperFiveYears(element, {
      ...options,
    });
  });
})();
