import Ready from '@/utils/ready';

// @ts-ignore
import Swiper from 'swiper';
import {Pagination, Autoplay, Navigation} from 'swiper';

Swiper.use([Pagination, Autoplay, Navigation]);

const selector = '[data-ref="slider"]';

(() => {
    Ready.watch(selector, (element: any) => {
        window.addEventListener('load', () => {
            let options = element.dataset && element.dataset.options ? JSON.parse(element.dataset.options) : {};
            const hasEvent = element.dataset?.hasEvent;
            const pagination = element.querySelectorAll('.swiper-slide').length > 1 && options?.pagination ? options.pagination : false;
            const slider = new Swiper(element, {
                slidesPerView: 1,
                breakpoints: {
                    998: {
                        slidesPerView: 2,
                    },
                    1400: {
                        slidesPerView: 3,
                    },
                },
                spaceBetween: 30,
                ...options,
                pagination: pagination,
            });

            if (hasEvent) {
                slider.on('slideChange', () => {
                    const event = new CustomEvent(hasEvent);
                   element.dispatchEvent(event);
                });
            }
        });
    });
})();
