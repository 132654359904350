import Scroller from '@/plugins/scroller';

(() => {
    let flashMessage = document.querySelector('[data-flash-message]');
    let offset = window.innerWidth < 768 ? 100 : 200;

    if (flashMessage) {
        let scroller: any = new Scroller(.75, offset);
        scroller.scrollTo(flashMessage);
    }
})();