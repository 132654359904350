function modal() {
    return {
        show: false,
        open() {
            this.show = true
            document.body.style.overflow = 'hidden'
        },
        close() {
            this.show = false
            document.body.style.overflow = 'auto'
        },
    }
}

function swiper() {
    return {
        show: false,
        open() {
            this.show = true
            document.body.style.overflow = 'hidden'
        },
        close() {
            this.show = false
            document.body.style.overflow = 'auto'
        },
    }
}

if(document.querySelector('.saas-page')) {
    Object.assign(window, {
        modal,
        swiper
    })
}