import Ready from '@/utils/ready';
// @ts-ignore
import Swiper from 'swiper';
import {Pagination, Autoplay, Navigation} from 'swiper';

Swiper.use([Pagination, Autoplay, Navigation]);

const selector = '[data-ref="swiperInsight"]';

class swiperInsight {
  el: any;
  instance: any = null;

  constructor(el: any, options = {}) {
    this.el = el.querySelector('[data-ref="swiperInsight"] .swiper-container');
    this.instantiate(this.el, options);
  }

  private instantiate(el: any, options: any) {
    this.instance = new Swiper(el, {
      ...options,
    });
    if(this.instance.slides.length > 3) {
       this.instance.destroy();
       this.instance = new Swiper(el, {
         loop: true,
         ...options,
       });
    }
  }
}

(() => {
  Ready.watch(selector, (element: any) => {
    let options = {
      navigation: {
        nextEl: '.swiperInsight__nav__right',
        prevEl: '.swiperInsight__nav__left',
      },
      slidesPerView: 1,
      breakpoints: {
        // when window width is >= 320px

        720: {
          spaceBetween: 32,
          slidesPerView: 1,
        },

        900: {
          spaceBetween: 32,
          slidesPerView: 1.7,
        },

        1280: {
          spaceBetween: 32,
          slidesPerView: 2.55,
        },
      },
    };
    if (element.dataset.options) {
      options = JSON.parse(element.dataset.options);
    }
    return new swiperInsight(element, {
      ...options,
    });
  });
})();
