const srcContext = require.context(
    '@',
    true,
    /\.(runtime|asset|style)\.(.*?)$/,
);
srcContext.keys().forEach(srcContext);

const templatesContext = require.context(
    '@root/templates',
    true,
    /\.(runtime|asset|style)\.(.*?)$/,
);
templatesContext.keys().forEach(templatesContext);
