import Vue from 'vue';
import MainSlider from '@/components/MainSlider.vue';
import Ready from '@/utils/ready';
import {AppBus} from '@/events/event-bus';
import Scroller from "@/plugins/scroller";
import {debounce} from 'lodash';
import Swiper from 'swiper';
// @ts-ignore
import {Pagination, Autoplay, Navigation} from 'swiper';

Swiper.use([Pagination, Autoplay, Navigation]);

const selector = '[data-ref="five-years"]';
let listItems: Element[] | null;
let sections: Element[] | null;


(() => {
    if (window.innerWidth >= 1400) {
        Ready.watch(selector, (element: any) => {
            let displayed = true;
            let displaying = false;
            let scroller = new Scroller(null, 0.1);

            if (!(window as any).AppBus) {
                (window as any).AppBus = AppBus;
            }


            const fiveYear = new Vue({
                computed: {
                    items() {
                        return JSON.parse(element.dataset.items);
                    }
                },
                components: {
                    MainSlider
                },
                template: `
                    <MainSlider :items="items"></MainSlider>`
            }).$mount(element);


            listItems = Array.from(document.querySelectorAll('[data-ref="fiveYearsList"] li'));
            sections = Array.from(document.querySelectorAll('[data-scroll-section]'));

            const checkCurrentListItem = (currentSectionNumber: number) => {
                (listItems as any).forEach((listItem: any) => {
                    if (Number((listItem as any).dataset.target) === currentSectionNumber) {
                        listItem.classList.add('active');
                    } else {
                        listItem.classList.remove('active');
                    }
                });
            };

            const checkCurrentSection = debounce(() => {
                let {innerHeight, scrollY} = window;
                if (scrollY !== 0) {
                    let currentSection = (sections as any).find((section: any) => {
                        let sectionY = section.getBoundingClientRect().y;
                        if ((sectionY < 0 ? sectionY : -(sectionY)) + (innerHeight / 2) > 0 && scrollY !== 0) {
                            return section;
                        }
                    });
                    checkCurrentListItem(Number(currentSection.getAttribute('data-scroll-section')));
                } else {
                    (window as any).AppBus.$emit('appear');
                    checkCurrentListItem(1);
                }
            }, 50);

            // const checkLogo = () => {
            //     isScrolled = window.scrollY !== 0;
            //     let logo = document.querySelector('.fiveYears__header__logo');
            //     if (isScrolled) {
            //         (logo as any).classList.add('active');
            //     } else {
            //         (logo as any).classList.remove('active');
            //     }
            // }

            (window as any).addEventListener('load', function () {
                setTimeout(() => {
                    window.addEventListener('scroll', checkCurrentSection);
                }, 50)
            });

            listItems.forEach((item, index) => {
                item.addEventListener('click', (e: any) => {
                    if (!displaying) {
                        if (index !== 0 && displayed) {
                            (window as any).AppBus.$emit('disappear');
                            scroller.scrollTo(`#section${Number((item as any).dataset.target)}`);
                        } else if (index !== 0 && !displayed) {
                            scroller.scrollTo(`#section${Number((item as any).dataset.target)}`);
                        } else if (index === 0 && !displayed) {
                            displaying = true;
                            scroller.scrollTo(0);
                        }
                    }
                });
            });

            (window as any).AppBus.$on('appeared', () => {
                displayed = true;
                displaying = false;
            });

            (window as any).AppBus.$on('start-appearing', () => {
                displaying = true;
            });

            (window as any).AppBus.$on('disappeared', () => {
                displayed = false;
                displaying = false;
            });

            (window as any).AppBus.$on('start-disappearing', () => {
                displaying = true;
            });

            (window as any).AppBus.$on('disappearing-with-scroll', () => {
                if (!displaying) {
                    scroller.scrollTo(200);
                }
            });
        });
    } else {
        Ready.watch(selector, (element: any) => {

            listItems = Array.from(document.querySelectorAll('.fiveYears__header__responsive__content__body__links__list__item'));
            sections = Array.from(document.querySelectorAll('[data-scroll-section]'));

            const checkCurrentListItem = (currentSectionNumber: number) => {
                (listItems as any).forEach((listItem: any, index: number) => {
                    if ((index + 1) === currentSectionNumber) {
                        listItem.classList.add('active');
                    } else {
                        listItem.classList.remove('active');
                    }
                });
            };

            const checkCurrentSection = debounce(() => {
                const {innerHeight} = window;
                let currentSection = (sections as any).find((section: any) => {
                    let sectionY = section.getBoundingClientRect().y;
                    if ((sectionY < 0 ? sectionY : -(sectionY)) + (innerHeight / 2) > 0) {
                        return section;
                    }
                });
                checkCurrentListItem(Number(currentSection.getAttribute('data-scroll-section')));
            }, 50);

            window.addEventListener('scroll', checkCurrentSection);

            let slider = new Swiper(element.querySelector('.swiper-container'), {
                slidesPerView: 1,
                spaceBetween: 50,
                autoplay: {
                    delay: 4000,
                },
                pagination: {
                    el: element.querySelector('.swiper-pagination'),
                    type: 'bullets',
                    clickable: true,
                    renderBullet: function (index: any, className: any) {
                        return '<span class="h-10 bg-transparent mx-2 flex items-center w-6 ' + className + '"><svg class="h-full w-full" viewBox="0 0 33 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.61688 14.4443L32.25 0.444336H0.75L7.61688 14.4443Z" fill="#EC6649"/></svg></span>';
                    },
                },
            });
            (window as any).responsiveMainSlider = slider;
            (window as any).scroller = new Scroller(null, 0.1);
        });
    }
})();
