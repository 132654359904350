<template>
    <div class="mainSlider" ref="mainSlider">
        <div class="mainSlider__wrapper">
            <div class="mainSlider__wrapper__direction">
                <div class="flex justify-center">
                    <div class="direction" :class="{'active': currentSlide - 1 >= 0}"
                         @click="slideTo(currentSlide - 1)"><i class="uil uil-arrow-up"></i></div>
                </div>
                <div class="flex mt-xs">
                    <div class="direction" @click="slideTo(currentSlide - 1)"><i class="uil uil-arrow-left"></i></div>
                    <div class="direction mx-xs active" @click="slideTo(currentSlide + 1)"><i
                            class="uil uil-arrow-down"></i></div>
                    <div class="direction" @click="slideTo(currentSlide + 1)"><i class="uil uil-arrow-right"></i></div>
                </div>
            </div>
            <div class="container h-full">
                <div class="-ml-containerSpace-1/4 h-full">
                    <div class="h-full w-full">
                        <div class="h-full" style="width: 640px">
                            <div class="mainSlider__wrapper__container relative">
                                <div class="mainSlider__wrapper__container__content w-full relative z-0">
                                    <transition @enter="transitionDateEnter" @leave="transitionDateLeave" mode="out-in">
                                        <span class="mainSlider__wrapper__date -z-1" :key="currentSlide">{{getDate(items[currentSlide].date)}}</span>
                                    </transition>
                                    <div class="z-0 bg-white p-2xl">
                                        <h2 class="heading heading--title6 heading--s3 mb-xl">
                                            <div class="heading__text">
                                                <transition @enter="transitionEnter" @leave="transitionLeave" mode="out-in">
                                                    <p :key="currentSlide" v-html="items[currentSlide].title"></p>
                                                </transition>
                                            </div>
                                            <span class="heading__claw">
                                                <span class="claw">
                                                <svg width="62.976" height="9.005568" viewBox="0 0 98 18" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 15.7143L27.6024 2L35.6988 15.7143L62.3012 2L70.3976 15.7143L97 2"
                                                          stroke="#EC6649" stroke-width="3"></path>
                                                </svg>
                                                </span>
                                            </span>
                                        </h2>
                                        <div class="mb-lg" v-if="currentSlide !== 0">
                                            <div class="flex">
                                                <span class="pill pill--secondary mr-md">Webinar</span>
                                                <span class="pill" v-if="items[currentSlide].date">{{ getDate(items[currentSlide].date, 'Do MMMM YYYY')}}</span>
                                            </div>
                                            <div class="heading heading--subtitle mt-sm">
                                                <div class="heading__text">
                                                    Présenté par
                                                    <transition @enter="transitionEnter" @leave="transitionLeave"
                                                                mode="out-in">
                                                        <i :key="currentSlide">
                                                            {{ items[currentSlide].author }}
                                                        </i>
                                                    </transition>
                                                </div>
                                            </div>
                                        </div>
                                        <transition @enter="transitionEnter" @leave="transitionLeave" mode="out-in">
                                            <p :key="currentSlide" class="text text--quote" v-html="items[currentSlide].content"></p>
                                        </transition>
                                        <transition name="fade">
                                            <ul v-show="currentSlide === 0" class="list--bulleted mt-lg">
                                                <li v-for="(intro, index) in getIntros" @click="slideTo(index + 1)"
                                                    class="list__item">
                                                    <div class="listItem__bullet">{{ index + 1 }}</div>
                                                    {{intro}}
                                                </li>
                                            </ul>
                                        </transition>
                                        <transition name="fade">
                                            <ul v-show="currentSlide !== 0" class="list--bulleted mt-lg">
                                                <a target="_blank" :href="items[currentSlide].link">
                                                    <button class="button">
                                                        <div class="button__icon">
                                                            <div class="icon__wrapper ">
                                                                <svg class="icon  " width="16" height="16" overflow="visible" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">            <path d="M16.0636 5.59091C16.6455 5.00909 16.6455 4.13636 16.0636 3.55455L13.4455 0.936364C12.8636 0.354545 11.9909 0.354545 11.4091 0.936364L0.5 11.8455V16.5H5.15455L16.0636 5.59091ZM12.4273 1.95455L15.0455 4.57273L12.8636 6.75455L10.2455 4.13636L12.4273 1.95455ZM1.95455 15.0455V12.4273L9.22727 5.15455L11.8455 7.77273L4.57273 15.0455H1.95455Z" fill="#0E3D55"></path>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div v-if="items[currentSlide].closed === false" class="button__content">
                                                            je m'inscris
                                                        </div>
                                                        <div v-if="items[currentSlide].closed === true" class="button__content">
                                                            voir le replay
                                                        </div>
                                                    </button>
                                                </a>
                                            </ul>
                                        </transition>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mainSlider__animation__wrapper" ref="wrapper">
            <div class="mainSlider__animation__numbers" v-for="(item, index) in items"
                 :style="{bottom: `${setNumbersDefaultPosY(index) - 40}px`, right: `${setNumbersDefaultPosX(index) + 45}px`}"
                 @click="slideTo(index)"
            >
                <div class="h-full w-full" :style="{backgroundImage: `url(${item.number})`}"></div>
            </div>
            <div class="mainSlider__animation__images" v-for="(item, index) in items"
                 @click="slideTo(index)"
                 :style="{
                    backgroundImage: `url(${item.image})`,
                    bottom: `${setImagesDefaultPosY(index)}px`,
                    right: `${setImagesDefaultPosX(index)}px`}"
            ></div>
        </div>
    </div>
</template>
<script>
    import {AppBus} from "@/events/event-bus";
    import {gsap, Expo, Power4} from 'gsap';
    import {debounce} from 'lodash';
    import moment from 'moment';

    export default {
        name: 'MainSlider',
        data() {
            return {
                finished: false,
                disappeared: false,
                animating: false,
                backdoor: 0,
                currentSlide: 0,
                images: null,
                numbers: null,
            }
        },
        props: {
            items: {
                default: [],
                required: true,
            },
        },
        created() {
            document.body.classList.add('overflow-y-hidden');
        },
        mounted() {
            this.backdoor = 1;
            this.setEvents();
            this.images = Array.from(document.querySelectorAll('.mainSlider__animation__images')).map((image) => {
                return {image: image, lastPosition: 0}
            });
            this.numbers = Array.from(document.querySelectorAll('.mainSlider__animation__numbers'));
            AppBus.$on('disappear', () => {
                if (!this.disappeared) {
                    this.disappear();
                }
            });
            AppBus.$on('appear', () => {
                if (this.disappeared) {
                    this.appear();
                }
            });
        },
        methods: {
            debounceWheel: debounce(function (e) {
                if (e.deltaY > 0) {
                    this.addCurrentSlide();
                } else {
                    this.removeCurrentSlide();
                }
            }, 200),
            setEvents() {
                window.addEventListener('wheel', (e) => {
                    this.debounceWheel.bind(this)(e);
                });
                document.addEventListener('keydown', (event) => {
                    if (event.key === 'ArrowUp') {
                        this.removeCurrentSlide();
                    } else if (event.key === 'ArrowDown') {
                        this.addCurrentSlide();
                    }
                });
                window.addEventListener('resize', (e) => {
                    this.backdoor = Math.random();
                });
            },
            appear() {
                this.slideTo(this.items.length);
                document.body.classList.add('overflow-y-hidden');
                AppBus.$emit('start-appearing');
                gsap.to('.mainSlider', 1.8, {
                    autoAlpha: 1,
                    ease: Power4.easeInOut,
                    onComplete: () => {
                        this.disappeared = false;
                        AppBus.$emit('appeared');
                    }
                });
            },
            disappear(params = false) {
                if (params) {
                    AppBus.$emit('disappearing-with-scroll');
                };
                document.body.classList.remove('overflow-y-hidden');
                AppBus.$emit('start-disappearing');
                gsap.to('.mainSlider', 1.8, {
                    autoAlpha: 0,
                    ease: Power4.easeInOut,
                    onComplete: () => {
                        this.disappeared = true;
                        AppBus.$emit('disappeared');
                    }
                });
            },
            animate() {
                this.animating = true;
                // Numbers
                this.numbers.forEach((item, index) => {
                    gsap.to(item, 1.8, {
                        x: -(this.getWrapperWidth / 2) * this.currentSlide + (this.currentSlide >= index ? 60 : 0),
                        y: -(this.getWrapperHeight / 2) * this.currentSlide,
                        ease: Expo.easeInOut,
                        onComplete: () => {
                            this.animating = false;
                            if (this.currentSlide === this.getItemsLength) {
                                this.finished = true;
                            }
                        },
                    });
                });
                // Images
                this.images.forEach((item, index) => {
                    const imagePosition = this.currentSlide - index;
                    if (index === 0) {
                        gsap.to(item.image, 1.8, {
                            opacity: this.currentSlide === 0 ? 1 : 0,
                            x: -(this.getWrapperWidth / 2) * this.currentSlide,
                            y: -(this.getWrapperHeight / 2) * this.currentSlide,
                            ease: Expo.easeInOut,
                        });
                    } else if (imagePosition === 1) {
                        gsap.to(item.image, 1.8, {
                            y: -((this.getWrapperHeight / 2) * index) - 420,
                            x: -((this.getWrapperWidth / 2) * index) - 620,
                            height: '119px',
                            width: '176px',
                            ease: Expo.easeInOut,
                        });
                    } else if (imagePosition === 2) {
                        gsap.to(item.image, 1.8, {
                            x: -((this.getWrapperWidth / 2) * index) - 620 + 109 + 86,
                            y: -((this.getWrapperHeight / 2) * index) - 420 - 74,
                            height: 74,
                            width: 109,
                            ease: Expo.easeInOut,
                        })
                    } else if (imagePosition === 3) {
                        gsap.to(item.image, 1.8, {
                            x: -((this.getWrapperWidth / 2) * index) - 620 + (109 * 2) + (86 * 2),
                            y: -((this.getWrapperHeight / 2) * index) - 420 - 60,
                            height: 74,
                            width: 109,
                            ease: Expo.easeInOut,
                        })
                    } else if (imagePosition === 4) {
                        gsap.to(item.image, 1.8, {
                            x: -((this.getWrapperWidth / 2) * index) - 620 + (109 * 3) + (86 * 3),
                            y: -((this.getWrapperHeight / 2) * index) - 420 - 15,
                            height: 74,
                            width: 109,
                            ease: Expo.easeInOut,
                        })
                    } else if (imagePosition <= 0) {
                        gsap.to(item.image, 1.8, {
                            x: -(this.getWrapperWidth / 2) * this.currentSlide,
                            y: -(this.getWrapperHeight / 2) * this.currentSlide,
                            height: '420px',
                            width: '620px',
                            ease: Expo.easeInOut,
                        });
                    }
                });
            },
            setNumbersDefaultPosX(index) {
                return ((index - 1) * -(this.getWrapperWidth / 2));
                // return (index * (-(this.getWrapperWidth / 2))) + (this.getWrapperWidth / 2);
            },
            setNumbersDefaultPosY(index) {
                return ((index - 1) * -(this.getWrapperHeight / 2));
                // return (index * (-(this.getWrapperHeight / 2))) + (this.getWrapperHeight / 2);
            },
            setImagesDefaultPosX(index) {
                return (-(this.getImageWidth / 2) + (-(this.getWrapperWidth / 4)) * ((index * 2) + 1) + (this.getWrapperWidth / 2));
            },
            setImagesDefaultPosY(index) {
                return (((index * 2) + 1) * (-(this.getWrapperHeight / 4)) + (this.getWrapperHeight / 2));
            },
            slideTo(index) {
                let slideNumber = index <= 0 ? 0 : (index >= this.getItemsLength ? this.getItemsLength : index);
                if (!this.animating && index !== this.currentSlide && !this.finished) {
                    this.finished = false;
                    this.currentSlide = slideNumber;
                    this.animate();
                } else if (this.finished && index < this.getItemsLength) {
                    this.finished = false;
                    this.currentSlide = slideNumber;
                    this.animate();
                } else if (this.finished && index > this.getItemsLength) {
                    this.disappear();
                }
            },
            addCurrentSlide() {
                if (!this.animating && !this.finished && this.currentSlide !== this.getItemsLength) {
                    this.currentSlide === (this.getItemsLength) ? this.currentSlide = (this.getItemsLength) : this.currentSlide += 1;
                    this.animate();
                } else if (!this.animating && this.finished && !this.disappeared) {
                    this.disappear(true);
                }
            },
            removeCurrentSlide() {
                if (!this.animating && !this.disappeared && this.currentSlide !== 0) {
                    this.finished = false;
                    this.currentSlide <= 0 ? this.currentSlide = 0 : this.currentSlide -= 1;
                    this.animate();
                }
            },
            transitionEnter(el, done) {
                gsap.set(el, {
                    opacity: 0,
                });
                gsap.to(el, {
                    opacity: 1,
                    duration: .8,
                    ease: Power4.easeInOut,
                    onComplete: done
                });
            },
            transitionLeave(el, done) {
                gsap.to(el, {
                    opacity: 0,
                    duration: 1,
                    ease: Power4.easeInOut,
                    onComplete: done
                });
            },
            transitionDateEnter(el, done) {
                gsap.set(el, {
                    top: -20,
                    opacity: 0,
                });
                gsap.to(el, {
                    top: 0,
                    opacity: 1,
                    duration: .9,
                    ease: Power4.easeInOut,
                    onComplete: done
                })
            },
            transitionDateLeave(el, done) {
                gsap.to(el, {
                    top: 20,
                    duration: .9,
                    opacity: 0,
                    ease: Power4.easeInOut,
                    onComplete: done
                })
            },
            getDate(date, format = 'Do MMM YYYY') {
                if (date) {
                    moment.locale('fr');
                    return moment(date).format(format);
                }
                return '';
            }
        },
        computed: {
            getIntros() {
                return this.items.filter((item) => item.intro).map((item) => item.intro);
            },
            getMainSliderHeight() {
                return this.backdoor === 0 ? 0 : (this.getWrapperHeight / 2) * this.items.length;
            },
            getWrapperHeight() {
                return this.backdoor === 0 ? 0 : this.$refs['wrapper'].offsetHeight;
            },
            getWrapperWidth() {
                return this.backdoor === 0 ? 0 : this.$refs['wrapper'].offsetWidth;
            },
            getImageWidth() {
                return this.backdoor === 0 ? 0 : document.querySelector('.mainSlider__animation__images').offsetWidth;
            },
            getItemsLength() {
                return this.items.length - 1;
            }
        },
    }
</script>
<style lang="scss" scoped>
    .heading--subtitle {
        font-weight: 700;

        i {
            letter-spacing: .6px;
            text-transform: capitalize;
        }
    }
</style>
<style lang="scss" scoped>
    @import '~@/styles/functions/all.scss';

    .fade-enter-active, .fade-leave-active {
        transition: opacity .8s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
        visibility: hidden;
    }

    .mainSlider {
        z-index: 1;
        height: 100vh;
        overflow: hidden;
        width: 100%;
        display: flex;
        position: fixed;
        background: theme(colors, gray, light);

        .mainSlider__wrapper {
            pointer-events: none;
            z-index: 1;
            position: absolute;
            width: 100%;
            height: 100vh;

            * {
                pointer-events: none;
            }

            .mainSlider__wrapper__direction {
                pointer-events: all;
                position: absolute;
                bottom: 50px;
                left: 50%;
                transform: translateX(-50%);
                transition: .5s ease-in-out;

                .direction {
                    cursor: pointer;
                    pointer-events: all;
                    height: 26px;
                    width: 26px;
                    border: 1px solid theme(colors, primary, light);
                    border-radius: 5px;
                    color: theme(colors, primary, light);
                    transition: .5s ease-in-out;

                    &:hover, &.active {
                        border-color: theme(colors, secondary, base);
                        color: theme(colors, primary, base);
                    }
                }
            }

            .mainSlider__wrapper__container {
                display: flex;
                align-items: center;
                height: 100%;
                width: 100%;

                .mainSlider__wrapper__container__content {
                    transform: translateY(50px);
                    pointer-events: all;

                    * {
                        pointer-events: all !important;
                    }
                }

                .mainSlider__wrapper__container__content__starter {
                    transition: 1.8s;
                    pointer-events: all;

                    * {
                        pointer-events: all !important;
                    }

                    &.is-hidden {
                        visibility: hidden;
                        opacity: 0;
                        z-index: -100;
                    }
                }

                .mainSlider__wrapper__date {
                    line-height: 5.25rem;
                    transform: translateY(-100%);
                    position: absolute;
                    top: 0;
                    right: 0;
                    font-size: 75px;
                    font-weight: bold;
                    text-shadow: 4px 4px 0px rgba(190, 197, 208, 0.5);
                    color: white;
                }
            }
        }

        .mainSlider__animation__wrapper {
            height: 100vh;
            width: calc(100%);
            left: 0;
            right: 0;
            /*margin-right: 64px;*/
            .mainSlider__animation__five {
                background: red;
                position: absolute;
                height: 371px;
                width: 622px;

                svg {
                    height: 100%;
                    width: 100%;
                }

                /*background: red;*/
                /*transform: translateY(40%);*/
            }

            .mainSlider__animation__numbers {
                position: absolute;
                height: 92px;
                width: 151px;
                cursor: pointer;

                svg {
                    height: 100%;
                    width: 100%;
                }
            }

            .mainSlider__animation__images {
                cursor: pointer;
                /*transform: translateX(100%);*/
                position: absolute;
                height: 420px;
                width: 620px;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }
        }
    }
</style>
