import Ready from '@/utils/ready';

const selector = '[data-ref="customUpload"]';

class CustomUpload {
    private el: any;
    private icons: any[];
    private readonly inputEl: any;
    private wrapper: any;
    private trads: any;

    constructor(element: HTMLElement) {
        this.el = element;
        this.icons = Array.from(this.el.querySelectorAll('[data-icon]'));
        this.inputEl = this.el.querySelector('input[type="file"]');
        this.wrapper = this.el.querySelector('[data-ref="customUploadFilename"]');
        this.trads = JSON.parse(this.el.dataset.trads || {});

        if (this.inputEl) {
            this.addEventListeners();
        }
    }

    private addEventListeners() {
        const clearIcon: any = this.icons.find((icon: any) => icon.dataset.icon === 'full');
        const changeEvent = new Event('change');
        const inputEvent = new Event('input');

        clearIcon.addEventListener('click', (e: any) => {
           e.preventDefault();

           this.inputEl.value = null;
           this.inputEl.dispatchEvent(changeEvent);
           this.inputEl.dispatchEvent(inputEvent);
        });

        this.inputEl.addEventListener('change', () => {
            // const filenameWrapper = this.el.querySelector('[data-ref="customUploadFilename"]');
            const filename = (this.inputEl as any).value.split('\\').pop();

            const downloadIcon: any = this.icons.find((icon: any) => icon.dataset.icon === 'empty');

            if (this.wrapper) {
                this.wrapper.innerHTML = `
                    <span 
                        class="customUpload__filename" 
                        title="${filename}">${filename}
                    </span>
                `;
            } else {
                const filenameNode = document.createElement('div');
                filenameNode.setAttribute('data-ref', 'customUploadFilename');
                filenameNode.innerHTML = `
                    <span 
                        class="customUpload__filename" 
                        title="${filename}">${filename}
                    </span>
                `;
                this.el.appendChild(filenameNode);
            }

            if (this.inputEl.files.length) {
                downloadIcon?.classList.add('hidden');
                clearIcon?.classList.remove('hidden');
            } else {
                downloadIcon?.classList.remove('hidden');
                clearIcon?.classList.add('hidden');

                this.wrapper.innerHTML = `<span class="opacity-50 md:text-xs text-2xs">${this.trads.empty}</span>`;
            }
        });
    }
}

(() => {
    Ready.watch(selector, (element: any) => {
        new CustomUpload(element);
    });
})();
