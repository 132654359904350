import Ready from '@/utils/ready';
import axios from 'axios';

const selector = '[data-ref="fiveYearsForm"]';
class FiveYearsForm {
    el: any;
    submit: any;
    error: any;

    constructor(el: any) {
        this.el = el;
        this.submit = this.el.querySelector('[submit]');
        this.error = this.el.querySelector('[error]');
        this.addEventListeners();
    }

    private addEventListeners() {
        this.submit.addEventListener('click', (e: any) => {
            e.preventDefault();
            if(this.el.querySelector('form').checkValidity() === true) {
                this.error.querySelector('svg').style.display = 'block';
                this.error.querySelector('span').innerText = '';
                this.el.querySelector('form').style.display = 'none';
                axios({
                    method: 'post',
                    url: window.location.href,
                    data: new FormData(this.el.querySelector('form'))
                })
                .then((response) => {
                    this.error.querySelector('svg').style.display = 'none';
                    if(response.status === 200) {
                        this.createSuccess();
                    }
                    else {
                        this.createError('Désolé, une erreur est survenue');
                    }
                })
                .catch((error) => {
                    this.el.querySelector('form').style.display = 'block';
                    this.error.querySelector('svg').style.display = 'none';
                    this.createError('Désolé, une erreur est survenue');
                })
            } else {
                this.el.querySelector('form').style.display = 'block';
                this.createError('Champs invalides')
            }
        });
    }

    private createSuccess() {
        this.error.querySelector('img').style.display = 'block';
        this.error.querySelector('span').classList.add('bg-secondary-base', 'p-sm', 'text-white')
        this.error.querySelector('span').innerText = 'Merci, votre témoignage a bien été envoyé !';
    }

    private createError(message: string) {
        this.error.querySelector('span').innerText = message;
    }
}

(() => {
    Ready.watch(selector, (element: HTMLElement) => {
        new FiveYearsForm(element);
    });
})();
