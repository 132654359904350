import Ready from '@/utils/ready';
// @ts-ignore
import Swiper from 'swiper';
import {Pagination, Autoplay, Navigation} from 'swiper';

Swiper.use([Pagination, Autoplay, Navigation]);

const selector = '[data-ref="swiperFiveYears"]';

class swiperFiveYears {
  el: any;
  instance: any = null;

  constructor(el: any, options = {}) {
    this.el = el.querySelector('[data-ref="swiperFiveYears"] .swiper-container');
    this.instantiate(this.el, options);
  }

  private instantiate(el: any, options: any) {
    this.instance = new Swiper(el, {
      ...options,
    });
  }
}

(() => {
  Ready.watch(selector, (element: any) => {
    let options = {
      navigation: {
        nextEl: '.fiveYears__slider__swiper__nav__right',
        prevEl: '.fiveYears__slider__swiper__nav__left',
      },
      slidesPerView: 1,
      breakpoints: {
        992: {
          spaceBetween: 32,
          slidesPerView: 3,
        },

        1200: {
          spaceBetween: 32,
          slidesPerView: 4,
        }
      },
      spaceBetween: 20,
      centeredSlides: false,

    };
    if (element.dataset.options) {
      options = JSON.parse(element.dataset.options);
    }
    return new swiperFiveYears(element, {
      ...options,
    });
  });
})();
