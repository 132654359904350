import Ready from '@/utils/ready';
import {AppBus} from '@/events/event-bus';
import axios from 'axios';

const selector = '[data-ref="cookies"]';

(() => {
    (window as any).AppBus = AppBus;
    Ready.watch(selector, (element: any) => {
        const recapCookie: any = document.querySelector('[data-ref="recap-cookie"]');
        const api = element.dataset.api;

        recapCookie.addEventListener('click', () => {
            recapCookie.classList.remove('visible');
            element.classList.add('visible');
        })

        const constructUrl = (items: any) => {
            let url: string = '';
            items.forEach((item: any, index: number) => {
                url = url + `${index === 0 ? '' : '&'}${item.name}=${item.checked}`;
            });
            return url;
        };

        AppBus.$on('submit', (cookies: any) => {
            let url = constructUrl(cookies);
            if (url) {
                axios.post(`${api}?${url}`).then(() => {
                    element.classList.remove('visible');
                    recapCookie.classList.add('visible');
                });
            }
        });
    })
})();
//
// class Cookies {
//     el: any;
//     confirmEl: any;
//     refuseEl: any;
//     confirmPath: any;
//     refusePath: any;
//     footer: any;
//
//     constructor(el: any) {
//         this.el = el;
//         this.footer = document.querySelector('[data-ref="footerContainer"]');
//         this.confirmEl = this.el.querySelector('[data-ref="cookiesConfirm"]');
//         this.refuseEl = this.el.querySelector('[data-ref="cookiesRefuse"]');
//         this.confirmPath = this.el.dataset.linkAccept;
//         this.refusePath = this.el.dataset.linkRefuse;
//         this.addEventListeners();
//     }
//
//     private addEventListeners() {
//         this.confirmEl.addEventListener('click', () => {
//             this.el.style.display = 'none';
//             this.footer.className = '';
//             const request = new XMLHttpRequest();
//             request.open('POST', this.confirmPath, true);
//             request.send();
//         });
//         this.refuseEl.addEventListener('click', () => {
//             this.el.style.display = 'none';
//             this.footer.className = '';
//             const request = new XMLHttpRequest();
//             request.open('POST', this.refusePath, true);
//             request.send();
//         });
//     }
// }
//
// (() => {
//     Ready.watch(selector, (element: HTMLElement) => {
//         new Cookies(element);
//     });
// })();
