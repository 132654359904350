import Ready from '@/utils/ready';
// @ts-ignore
import Swiper from 'swiper';
import {Pagination, Autoplay, Navigation} from 'swiper';

Swiper.use([Pagination, Autoplay, Navigation]);

const selector = '[data-ref="keypointsImages"]';

class swiperFiveYears {
  el: any;
  instance: any = null;

  constructor(el: any, options = {}) {
    this.el = el.querySelector('[data-ref="keypointsImages"] .swiper-container');
    this.instantiate(this.el, options);
  }

  private instantiate(el: any, options: any) {
    this.instance = new Swiper(el, {
      ...options,
    });
  }
}

(() => {
  Ready.watch(selector, (element: any) => {
    let options = {
      pagination: {
        el: '[data-ref="keypointsImages"] .swiper-pagination',
        type: 'bullets',
        clickable: true,
        renderBullet: function (index: any, className: any) {
          return '<span class="h-10 bg-transparent mx-2 inline-block w-6 ' + className + '"><svg viewBox="0 0 33 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.61688 14.4443L32.25 0.444336H0.75L7.61688 14.4443Z" fill="#EC6649"/></svg></span>';
        },
      },
      slidesPerView: 1,
      spaceBetween: 32,
    };
    if (element.dataset.options) {
      options = JSON.parse(element.dataset.options);
    }
    return new swiperFiveYears(element, {
      ...options,
    });
  });
})();
