



























import gsap from 'gsap';
import { Vue, Component, Prop } from 'vue-property-decorator';
import Ready from '@/utils/ready';

@Component
export default class WordLoop extends Vue {
    @Prop({ default: '' }) dataWords!: string;
    private height: any = 31;
    private currentWordIndex: any = 1;
    private interval: any = null;
    private timeInterval: any = 1250;
    get words() {
        const words = this.dataWords
            .split(',')
            .map((item) => item.trim());
        if (words.length > 0) {
            words.push(words[0])
        }
        return words
    }

    mounted() {
        this.start();
    }

    start() {
        this.setHeight();
        this.createInterval();
    }

    addEventListeners() {
        document.addEventListener('DOMContentLoaded', this.start.bind(this));
    }

    createInterval() {
        this.interval = setInterval(this.nextWord, this.timeInterval)
    }

    setHeight() {
        this.height = this.$refs.listItem[0].offsetHeight;
        (<HTMLElement>this.$refs['wordLoop']).style.height =  this.height + 'px';
    }

    nextWord() {
        this.setHeight()
        const { currentWordIndex } = this;
        gsap.to(this.$refs['list'], {
            duration: .3,
            y: currentWordIndex * this.height * -1,
            callbackScope: this,
            onComplete() {
                if (this.currentWordIndex === this.words.length - 1) {
                    gsap.set(this.$refs['list'], {
                        y: 0
                    })
                    this.currentWordIndex = 1;
                    return
                }
                this.currentWordIndex = this.currentWordIndex + 1;
                return;
            }
        })
    }
}

(() => {
    Ready.watch(
        '[data-v-component="WordLoop"]',
        (element: HTMLElement) => {
            if (!element.getAttribute('data-watched')) {
                element.setAttribute('data-watched', 'true');
                const attributes = Array.from((element as any).attributes) || [];
                new (Vue as any)({
                    components: {
                        WordLoop,
                    },
                    template: `<WordLoop ${attributes
                        .map((attribute: any) => `${attribute.name}='${attribute.value}'`)
                        .reduce(
                            (accumulator: string, currentValue: string) =>
                                accumulator + ' ' + currentValue,
                            '',
                        )} />`,
                }).$mount(element);
            }
        },
    );
})();
