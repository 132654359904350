import Scroller from './scroller';

class ScrollToSection {
    private readonly links: any;
    private readonly offset: any;
    private scroller: any;

    constructor() {
        this.links = document.querySelectorAll(`[data-scope]`);
        this.offset = window.innerWidth < 992 ? 70 : 100;
        this.scroller = new Scroller(.75, this.offset);
        if (this.links[0]) {
            this.addEventListeners();
        }
    }

    public addEventListeners() {
        [].forEach.call(this.links, (link: any) => {
            const scope = link.dataset.scope;
            if (scope) {
                link.addEventListener('click', () => {
                    this.scroller.scrollTo(scope);
                });
            }
        });
    }
}

(() => {
    new ScrollToSection();
})();
