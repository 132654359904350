import Ready from '@/utils/ready';

(() => {
    Ready.watch('[data-simplebar]', (element: HTMLElement) => {
        import('./customScrollbar').then((CustomScrollbar: any) => {
            const options = element.dataset.customScrollbarOptions;
            new CustomScrollbar.default(
                element,
                options ? JSON.parse(options) : {},
            );
        });
    });
})();