import Ready from '@/utils/ready';
import Choices from 'choices.js';

const selector = '[data-select]';

const defaultConfig = {
    searchEnabled: false,
    itemSelectText: '',
};

(() => {
    Ready.watch(selector, (element: any) => {
        let options = {};
        if (element.dataset.options) {
            options = JSON.parse(element.dataset.options);
        }
        const choice = new Choices(element, {
            ...defaultConfig,
            ...options,
        });
    });
})();

export {defaultConfig};
