import { debounce } from 'lodash';

function csHeader() {
    return {
        el: null,
        titleEl: null,
        init() {
            this.el = (this as any).$el;
            this.titleEl = (this as any).$refs.title;
            if (this.titleEl) {
                this.setElementMargin();
                window.addEventListener('resize', debounce(this.setElementMargin.bind(this), 100));
            }
        },
        setElementMargin() {
            (this as any).el.style.marginBottom = this.getTitleHeight() / 2 + 'px';
        },
        getTitleHeight() {
            return (this as any).titleEl.offsetHeight;
        },
    };
}

(window as any).csHeader = csHeader;
