import {gsap, Power4} from 'gsap';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

class Scroller {
    private readonly duration: number = .8;
    private readonly offset: number = 80;

    constructor(duration: any = .8, offset: number = 80) {
        this.duration = duration || this.duration;
        this.offset = offset || this.offset;
    }

    public scrollTo(target: HTMLElement | string | number, callback: any = null) {
        let targetEl: any = target;

        if (typeof targetEl === 'string') {
            targetEl = document.querySelector(targetEl);
        }

        if (targetEl) {
            gsap.to(window, {duration: this.duration, ease: Power4.easeInOut, scrollTo: {y: targetEl, offsetY: this.offset}});
        }

        if (callback !== null) {
            callback();
        }
    }
}

export default Scroller;
