import Ready from '@/utils/ready';
import {TimelineMax, Linear} from 'gsap';

const selector = '[data-ref="animationLine"]';

class AnimationLine {
    private el: HTMLElement;
    private itemPerView: any = null;
    private direction: string = 'default';
    private wrapper: HTMLElement | null;
    private ulList: HTMLElement | null;
    private ulListLi: HTMLCollection | null;
    private ulListClone: HTMLElement | null;
    private ulListWidth: number = 0;
    private time: number = 0;
    private speed: number = 8;
    private timeline: any;

    constructor(element: HTMLElement) {
        // Set dom elements
        this.el = element;
        this.wrapper = this.el.querySelector('[data-ref="wrapper"]');
        this.ulList = this.el.querySelector('[data-ref="list"]');
        this.ulListLi = (this as any).ulList.querySelectorAll('li');
        // Set direction
        if (this.el.dataset.direction) {
            this.direction = this.el.dataset.direction;
        }
        // Set speed
        if (this.el.dataset.speed) {
            this.speed = Number(this.el.dataset.speed);
        }
        // Set Item per View
        this.setItemPerView();

        // Set width of each li and width of ul
        this.setItemWidth();
        // Clone and happend a the new ul
        this.ulListClone = (this as any).ulList.cloneNode(true);
        (this as any).ulListClone.classList.add('cloned');
        (this as any).wrapper.appendChild((this as any).ulListClone);
        // set scrolling time
        this.time = this.speed * (this as any).ulListLi.length;
        // Set timeline
        this.timeline = new TimelineMax({repeat: -1});
        this.init();
    }

    private init() {
        if (this.direction === 'default') {
            this.timeline
                .fromTo(
                    this.ulList,
                    this.time,
                    {rotation: 0.01, x: 0},
                    {force3D: true, x: -this.ulListWidth, ease: Linear.easeNone},
                    0,
                )
                .fromTo(
                    this.ulListClone,
                    this.time,
                    {rotation: 0.01, x: this.ulListWidth},
                    {force3D: true, x: 0, ease: Linear.easeNone},
                    0,
                )
                .set(this.ulList, {
                    force3D: true,
                    rotation: 0.01,
                    x: this.ulListWidth,
                })
                .to(
                    this.ulListClone,
                    this.time,
                    {
                        force3D: true,
                        rotation: 0.01,
                        x: -this.ulListWidth,
                        ease: Linear.easeNone,
                    },
                    this.time,
                )
                .to(
                    this.ulList,
                    this.time,
                    {force3D: true, rotation: 0.01, x: 0, ease: Linear.easeNone},
                    this.time,
                )
                .progress(1)
                .progress(0);
        } else {
            this.timeline
                .fromTo(
                    this.ulList,
                    this.time,
                    {rotation: 0.01, x: 0},
                    {force3D: true, x: this.ulListWidth, ease: Linear.easeNone},
                    0,
                )
                .fromTo(
                    this.ulListClone,
                    this.time,
                    {rotation: 0.01, x: -this.ulListWidth},
                    {force3D: true, x: 0, ease: Linear.easeNone},
                    0,
                )
                .set(this.ulList, {
                    force3D: true,
                    rotation: 0.01,
                    x: -this.ulListWidth,
                })
                .to(
                    this.ulListClone,
                    this.time,
                    {
                        force3D: true,
                        rotation: 0.01,
                        x: this.ulListWidth,
                        ease: Linear.easeNone,
                    },
                    this.time,
                )
                .to(
                    this.ulList,
                    this.time,
                    {force3D: true, rotation: 0.01, x: 0, ease: Linear.easeNone},
                    this.time,
                )
                .progress(1)
                .progress(0);
        }
    }

    private setItemPerView() {
        let itemPerView: number = Number(this.el.dataset.itemPerView);

        if (window.innerWidth < 992) {
            itemPerView = Math.floor(itemPerView / 2);
        } else if (window.innerWidth < 1400) {
            itemPerView = Math.floor(itemPerView / 1.35);
        }

        if (itemPerView && itemPerView <= (this as any).ulListLi.length) {
            this.itemPerView = itemPerView;
        } else {
            this.itemPerView = (this as any).ulListLi.length;
        }
    }

    private setItemWidth() {
        (this as any).ulListLi.forEach((list: HTMLElement) => {
            let width: number = 0;
            if (window.innerWidth <= 500) {
                width = (this as any).wrapper.offsetWidth;
            } else {
                width = (this as any).wrapper.offsetWidth / Number(this.itemPerView);
            }
            list.style.width = `${width}px`;
            this.ulListWidth += width;
            (this as any).ulList.style.width = `${this.ulListWidth}px`;
        });
    }
}

(() => {
    Ready.watch(selector, (element: any) => {
        new AnimationLine(element);
    });
})();
