import {gsap, Power4} from 'gsap';
import Ready from "@/utils/ready";

const selector = '[data-ref="textSlider"]';

(() => {
    Ready.watch(selector, (element: any) => {
        window.addEventListener('load', () => {
            let index: number = 0;
            let slides: any = JSON.parse(element.dataset.text)?.content || [];

            let textCache: any = element.querySelector('[data-text-overlay]');
            let text: any = element.querySelector('[data-text-el]');

            const iterateIndex = () => {
                if (index === (slides.length - 1)) {
                    index = 0;
                } else {
                    index = index + 1;
                }
            };
            // text.style.display = 'block';
            //
            //
            // let sliderWidth: any = element.querySelector('.sliderWidth .heading__text');
            //
            // slides.forEach((slide: any) => {
            //     sliderWidth.insertAdjacentHTML('beforeend', `${slide}<br>`);
            // });
            //
            // let getSlideText = function () {
            //     if (index < slides.length - 1) {
            //         let result = slides[index];
            //         index = index + 1;
            //         return result;
            //     } else {
            //         let result = slides[index];
            //         index = 0;
            //         return result;
            //     }
            // }
            //
            // text.innerText = getSlideText();
            //
            // element.style.height = `${text.offsetHeight + 5}px`;
            //
            let timeline = gsap.timeline({repeat: -1, repeatDelay: 2});
            // timeline.delay(.5);
            timeline.from(textCache, {y: '100%', duration: 0.4, ease: Power4.easeInOut});
            timeline.to(textCache, {y: '0%', duration: 0.2, ease: Power4.easeInOut});
            timeline.call(() => {
                iterateIndex();
                text.innerHTML = slides[index];
            });
            timeline.to(textCache, {y: '-100%', duration: 0.5});
        })
    });
})();
