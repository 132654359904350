































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import Ready from '@/utils/ready';
import { TweenMax } from 'gsap';

@Component
export default class UiCursor extends Vue {
  posX: number = 0;
  posY: number = 0;
  mouseX: number = 0;
  mouseY: number = 0;
  hover: boolean = false;
  hidden: boolean = false;
  hiddenElement: HTMLElement | null = null;
  tweens: TweenMax[] = [];
  @Prop({ default: 'true' }) dataInPlace!: string;

  addCSS() {
    const css = '* { cursor: none!important; }',
      head = document.head || document.getElementsByTagName('head')[0],
      style = document.createElement('style');

    head.appendChild(style);

    style.type = 'text/css';
    if ((style as any).styleSheet) {
      // This is required for IE8 and below.
      (style as any).styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }
  }

  addTweens() {
    const pointer = this.$refs['pointer'] as any;
    const follower = this.$refs['follower'] as any;
    this.tweens[this.tweens.length] = TweenMax.to({}, 0.016, {
      repeat: -1,
      onRepeat: () => {
        this.posX += (this.mouseX - this.posX) / 9;
        this.posY += (this.mouseY - this.posY) / 9;
        TweenMax.to(this.$refs['pointer'], 0.05, {
          css: {
            top: this.mouseY - pointer.offsetHeight / 2,
            left: this.mouseX - pointer.offsetWidth / 2,
          },
        });
        TweenMax.to(follower, 0.05, {
          css: {
            top: this.posY - follower.offsetHeight / 2,
            left: this.posX - follower.offsetHeight / 2,
          },
        });
      },
    });
  }

  updatePositions(e: any) {
    this.mouseX = e.clientX;
    this.mouseY = e.clientY;
    if (this.dataInPlace === 'true') {
      this.updateInPlacePositions(e);
    }
  }

  updateInPlacePositions(e: any) {
    const follower = this.$refs['follower'] as any;
    if (this.hiddenElement && this.hidden) {
      const { xPercent, yPercent } = this.getCursorOnHiddenElementPositions(e);
      TweenMax.to(follower, 0.1, {
        xPercent: xPercent - 50,
        yPercent: yPercent - 50,
      });
      return;
    }
  }

  updateCursorState(e: any) {
    if (this.dataInPlace === 'false') {
      this.hover = this.hasFocusableElement(e.target);
    }
    this.hidden = this.hasHiddenElement(e.target);
  }

  getCursorOnHiddenElementPositions(event: any) {
    const referenceElement = this.hiddenElement;
    if (referenceElement) {
      const position = {
        x: event.pageX,
        y: event.pageY,
      };

      const offset = {
        left: referenceElement.offsetLeft,
        top: referenceElement.offsetTop,
      };

      let reference: any = referenceElement.offsetParent;

      while (reference) {
        offset.left += reference.offsetLeft;
        offset.top += reference.offsetTop;
        reference = reference.offsetParent;
      }
      const x = position.x - offset.left;
      const y = position.y - offset.top;
      return {
        x,
        y,
        xPercent: Math.round((x / referenceElement.offsetWidth) * 1000) / 10,
        yPercent: Math.round((y / referenceElement.offsetHeight) * 1000) / 10,
      };
    }
    return {
      x: 0,
      y: 0,
      xPercent: 0,
      yPercent: 0,
    };
  }

  hasFocusableElement(element: any): boolean {
    const focusableElementsList: string[] = [
      'a[href]',
      'button:not([disabled])',
      'area[href]',
      'input:not([disabled])',
      'select:not([disabled])',
      'textarea:not([disabled])',
      'iframe',
      'object',
      'embed',
      '*[tabindex]',
      '*[contenteditable]',
      '[data-cursor="focus"]',
      '[data-cursor="hover"]',
    ];

    if (!element.parentNode) {
      return false;
    }
    if (
      focusableElementsList.some((focusableElement: string) =>
        element.matches(focusableElement),
      )
    ) {
      return true;
    }
    return this.hasFocusableElement(element.parentNode);
  }

  hasHiddenElement(element: any): boolean {
    const hiddenElementsList: string[] = ['[data-cursor="hidden"]'];
    if (!element.parentNode) {
      return false;
    }
    if (
      hiddenElementsList.some((focusableElement: string) =>
        element.matches(focusableElement),
      )
    ) {
      this.hiddenElement = element;
      return this.dataInPlace === 'true'
        ? Array.from(element.getElementsByTagName('*')).includes(
            this.$refs['cursor'],
          )
        : true;
    }
    return this.hasHiddenElement(element.parentNode);
  }

  addEventListeners() {
    document.addEventListener('mousemove', this.onMouseMove.bind(this));
  }

  removeEventListeners() {
    document.removeEventListener('mousemove', this.onMouseMove.bind(this));
    this.tweens.forEach((tween) => tween.kill());
  }

  onMouseMove(e: any) {
    this.updatePositions(e);
    this.updateCursorState(e);
  }

  mounted(): void {
    if (this.dataInPlace === 'false') {
      this.addTweens();
      this.addCSS();
    } else {
    }
    this.addEventListeners();
  }

  beforeDestroy() {
    this.removeEventListeners();
  }
}

(() => {
  Ready.watch('[data-v-component="cursor"]', (element: HTMLElement) => {
    if (!element.getAttribute('data-watched')) {
      element.setAttribute('data-watched', 'true');
      const attributes = Array.from((element as any).attributes) || [];
      new (Vue as any)({
        components: {
          UiCursor,
        },
        template: `<UiCursor ${attributes
          .map((attribute: any) => `${attribute.name}='${attribute.value}'`)
          .reduce(
            (accumulator: string, currentValue: string) =>
              accumulator + ' ' + currentValue,
            '',
          )} />`,
      }).$mount(element);
    }
  });
})();
