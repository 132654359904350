import {gsap, Power2} from 'gsap';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';
// import scrollTo = gsap.plugins.scrollTo;

gsap.registerPlugin(ScrollToPlugin);

class Scroller {
    private readonly duration: number = .75;
    private readonly offset: number = 0;

    constructor(duration: any, offset: number) {
        this.duration = duration || this.duration;
        this.offset = offset || this.offset;
    }

    public scrollTo(target: string) {
        if (document.querySelector(`#${target}`)) {
            gsap.to(window, {
                duration: this.duration,
                ease: Power2.easeInOut,
                scrollTo: {
                    y: `#${target}`,
                    offsetY: this.offset,
                },
            });
        }
    }
}

export default Scroller;
